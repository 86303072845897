import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import Link from '../../../core/Link';

import SolutionsPage from '../components/SolutionsPage';

import heritageLogo from '../../../../images/logos/Heritage-house-transparent-crop.svg';
import threeFallsLogo from '../../../../images/logos/three-falls-cove.png';
import eightDyer from '../../../../images/logos/8dyer-transparent.svg';
import trailsideLogo from '../../../../images/logos/Trailside-transparent-crop.svg';

import hero from './images/hero.png';
import support from './images/support_panel.png';
import payment from './images/payment_panel.png';
import simplicity from './images/simplicity_panel.png';
import booking_engine_home from './images/booking_engine_home.png';
import guest_experience from './images/guest_experience_panel.png';
import channel_manager_diagram from './images/channel_mgr_diagram.svg';

import CenteredCardWithImage from '../../../ui-library/Hero/CenteredCardWithImage';
import LogoScroll from '../../../ui-library/LogoScroll';
import SimpleFeatureGrid from '../../../ui-library/FeatureSections';
import TwoColumnCta from '../../../ui-library/TwoColumnCta';
import SimpleCTASection from '../../../ui-library/CTASections';
import Testimonial from '../../../ui-library/Testimonials/Testimonial';
import HeaderSection from '../../../ui-library/HeaderSection';
import SimpleCenteredTestimonial from '../../../ui-library/Testimonials/SimpleCenteredTestimonial';
import SimpleThreeColumn from '../../../ui-library/FeatureSections/SimpleThreeColumn';

const logos = [
  {
    src: heritageLogo,
  },
  {
    src: threeFallsLogo,
  },
  {
    src: eightDyer,
  },
  {
    src: trailsideLogo,
  },
];

export class VacationRentalsPage extends Component {
  render() {
    return (
      <SolutionsPage>
        <Helmet title="Vacation Rental Management Software - ThinkReservations">
          <meta
            name="description"
            content="ThinkReservations' vacation rental management software helps owners and 
            managers make booking much easier for guests. Click here to learn more.
            "
          />
          <script
            type="application/ld+json"
            children={JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Service',
              serviceType: 'Vacation Rental Management Software',
              category:
                'Reservation Management Software for Small and Boutique Hotels',
              provider: {
                '@type': 'Organization',
                name: 'ThinkReservations',
              },
              areaServed: {
                '@type': 'Country',
                name: 'USA',
              },
            })}
          />
        </Helmet>
        <CenteredCardWithImage
          Img={() => (
            <img
              className="h-full w-full object-cover"
              src={hero}
              alt="People recieving their keys at a vacation rental"
            />
          )}
          heading="Reservation Management Software For Vacation Rentals + Short-Term Properties"
          subheading="Operating a vacation, short-term, or holiday rental business means you offer a more than a place to stay. Your guests expect to feel like they’re at their home away from home - with a twist. This is why choosing our vacation rental management software solution can be such a differentiator."
        />
        <SimpleFeatureGrid />
        <TwoColumnCta
          headline="Simplicity + Efficiency for Your Staff"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  If you're a vacation rental business owner who wants to
                  simplify and optimize how you manage your business, then
                  ThinkReservations is the perfect solution for you. Imagine
                  your day-to-day tasks becoming more automated and less
                  stressful with the help of our powerful all-in-one{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/booking-engine"
                  >
                    property management software
                  </Link>
                  . You'll get an intuitive, easy-to-use solution that you and
                  your team will appreciate.
                </p>
                <p>
                  And we'll help make sure your team is trained and comfortable
                  from day one. You won't need to set aside days or weeks to get
                  everyone up and running.
                </p>{' '}
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={simplicity}
              alt="A staff memeber greeting a customer out front of the rental"
            />
          )}
        />
        <div className="my-32"></div>
        <SimpleCenteredTestimonial
          quote={`We are so pleased to partner with Think - start to finish, there was never a doubt that we were in good hands. Every effort was made to make our onboarding process as seamless as possible and we are so appreciative for the support, guidance and all the attention to detail. The team is absolutely amazing, top notch customer service, and exceptional support.`}
          author="Donna + Asim Khawaja"
          position="Greenview Manor B&B"
        />
        <div className="my-32"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Upgrade the Guest Experience from the Start"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  At ThinkReservations, we believe that the guest experience
                  starts the moment they visit your website. That's why we offer
                  a{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/booking-engine"
                  >
                    mobile-responsive booking engine.
                  </Link>{' '}
                  that blends seamlessly, making it easy for guests to book
                  directly with you no matter what device they’re on. With the
                  intuitive interface and customization, you can tailor the
                  booking experience to match the specific needs of your
                  short-term rental guests, putting all the information they
                  need right at their fingertips. And because our booking engine
                  is integrated with our{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/booking-engine"
                  >
                    property management system
                  </Link>
                  , you can manage all of your reservations in one place.
                </p>
                <p>
                  With the ability to upsell guests on rooms, packages, and
                  amenities, you can increase your revenue year over year. Your
                  guests will appreciate the convenience of being able to add on
                  items as they're making their reservations, and you'll love
                  the added income! Whether it's a romance package for a special
                  occasion or a custom late check-out for a business traveler,
                  your online booking engine can help you boost your bottom
                  line.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={guest_experience}
              alt="A screenshot of the booking engine showing a room."
            />
          )}
        />
        <div className="my-24"></div>
        <SimpleCTASection
          Img={() => (
            <img
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
              src={booking_engine_home}
              alt="App screenshot showing the landing page for the booking engine."
            />
          )}
        />
        <div className="my-24"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Bye Bye Double-Bookings"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  The days of worrying about overbooking are behind you with our
                  <Link
                    className="text-think-blue"
                    to="/products/channel-manager"
                  >
                    channel manager
                  </Link>
                  . We send real-time two way availability and pricing to sites
                  like Booking, Expedia, Airbnb, HotelTonight and Tripadvisor.
                  You’ll rest easy knowing that your OTA listings are always
                  accurate without you or your team having to rush to manually
                  make updates.
                </p>
                <p>
                  We also know that while OTAs can be a significant source of
                  income for some vacation rental and short-term property
                  business, you may not need to list all of your units every
                  night of the year. That’s why we provide you with the control
                  and flexibility to only share what you want when you want. No
                  need to pay commission for reservations you can easily book on
                  your own!
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <div className="bg-slate-50 p-8">
              <img
                className="w-full"
                src={channel_manager_diagram}
                alt="A diagram outlining the several connections available in the channel manager."
              />
            </div>
          )}
        />
        <div className="my-44"></div>
        <TwoColumnCta
          headline="Rely on Data for Better Business Decisions"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  You need a way to stay on top of your company's performance?
                  Our{' '}
                  <Link
                    className="text-think-blue"
                    to="/resources/articles/reports"
                  >
                    robust reporting
                  </Link>{' '}
                  features are designed for that. Get an overview or dive deep
                  into the data with ease and quickly find areas where you can
                  improve, all while staying in sync across departments!
                </p>
                <p>
                  With our{' '}
                  <Link
                    className="text-think-blue"
                    to="/resources/webinars/reports"
                  >
                    Booking pace report
                  </Link>
                  , you can see how many rooms are booked for any given period
                  and compare with another timeframe. Using your historical
                  booking data over time will allow patterns to form that
                  predict the future of occupancy rates or marketing strategies
                  needed in order make adjustments on-the fly! This report is
                  essential when forecasting revenue as well because it gives
                  insight into what has worked previously so we know which
                  tactics might work again soon...and helping generate more
                  money!!
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <div className="bg-think-orange px-8">
              <Testimonial
                logo={null}
                avatar={null}
                name="Marty E."
                position="Flag House Inn"
                quote="Think has supported a 40% increase in business. We did a back to back to back demo of 3 systems and Think was easy winner. We worked with them to plan a fast go live after we bought the business."
              />
            </div>
          )}
        />
        <div className="my-44"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Use Your Choice of Payment Processor"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  We know that you need to take payments in the way for your
                  business, which is why we offer free integrations with a
                  variety of{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/payment-processing"
                  >
                    payment processors
                  </Link>{' '}
                  Our{' '}
                  <Link
                    className="text-think-blue"
                    to="/articles/pci-compliance"
                  >
                    PCI-compliant
                  </Link>{' '}
                  connects securely and handles all of your payments fast.
                  You’ll have access to cards stored within our system as well -
                  collecting balances at check-in or charging for extras during
                  their stay. And if you’d like to keep guest cards on file for
                  future bookings, you have that option, as well!
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={payment}
              alt="A couple entering credit card info on a tablet."
            />
          )}
        />
        <div className="my-24"></div>
        <HeaderSection
          title="Integrate Your Favorite Third-Party Software"
          copy={null}
          children={
            <p className="max-w-3xl mt-5 mx-auto text-xl text-gray-500">
              It’ll be easier than ever to manage your business when you connect
              the tools you love with your ThinkReservations account. We offer{' '}
              <Link className="text-think-blue" to="/products/integrations">
                integrations
              </Link>{' '}
              with a variety of third-party software and are always looking to
              add more. With options like OTAs, point-of-sale, and guest
              communication, you’ll have everything you need seamlessly
              connected.
            </p>
          }
        />
        <div className="my-24"></div>
        <LogoScroll logos={logos} />
        <div className="my-44"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Keep All of Your Historic Data"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  You may be wondering how to keep your old reservations and
                  guest details when you upgrade. We’ve thought of that, too!
                  We'll import all of your historic data for free for you. No
                  complicated spreadsheets to fill out or pricey fees. You’ll be
                  able to pick up right where you left off confident that
                  everything you need is right where you expect.
                </p>
                <p>
                  Our team has successfully handled 1000+ data imports from a
                  wide variety of property management systems, so we’re
                  confident we can do the same for you.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <div className="bg-think-orange px-8">
              <Testimonial
                logo={null}
                avatar={null}
                name="Hermann"
                position="MO - Luxury Hotel"
                quote="ThinkReservations is a booking revolution This system will make it so easy to keep your business running with minimal effort. All the day to day operations of managing your reservations simply takes care of itself."
              />
            </div>
          )}
        />
        <div className="my-44"></div>
        <h1 className="max-w-7xl mx-auto px-4 mt-1 block text-4xl text-center tracking-tight font-extrabold sm:text-5xl">
          Professional Service + Support You Can Rely On
        </h1>
        <SimpleThreeColumn />
        <TwoColumnCta
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  We treat you like you treat your guests. We’re proud to have a
                  dedicated team of Customer Success specialists (many with
                  real-world hospitality experience). You’ll rest assured
                  knowing that if you need anything, our team is here for you.
                </p>
                <p>
                  When you become a Thinker (we like to refer to our clients as
                  Thinkers!), you’ll receive comprehensive onboarding, account
                  setup, and training. We also have an online self-service
                  support center with articles and videos to help you if
                  questions pop up. And our US-based customer support is
                  available via email, phone, and chat, all included in your
                  subscription. We’ve even got emergency phone support
                  after-hours just in case.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={support}
              alt="A happy customer talking with support."
            />
          )}
        />
        <div className="my-24"></div>
        <SimpleCenteredTestimonial
          quote={`The entire process, from speaking with a sales representative to installation and training to going live, was absolutely seamless on ThinkReservation's end. Would that all other digital transitions go as smoothly! Thank you very much!`}
          author="David Johnson"
          position="Tradewinds on the Bay"
        />

        <HeaderSection
          title="Supporting Independent Lodging Properties for Over 10 Years"
          copy={null}
          subtitle={null}
          children={
            <>
              <p className="max-w-3xl mt-5 mx-auto text-xl text-gray-500">
                <p>
                  We've been working with vacation and short-term rental
                  businesses for over 10 years and we're dedicated to helping
                  you succeed. We understand the unique challenges and
                  opportunities you face running a lodging business and we’re
                  here to help you meet the evolving needs of today's travelers.{' '}
                  <Link className="text-think-blue" to="/products/integrations">
                    Learn more about our team.
                  </Link>{' '}
                </p>
                <p>
                  Choose a property management solution that will help you grow
                  your business while delivering the exceptional experience your
                  guests have come to expect.
                </p>
              </p>
              <Link to="/request-a-demo">
                <button
                  className="block max-w-xl mx-auto w-full my-4 rounded-md border border-transparent px-5 py-3 bg-[#e27d4e] text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 sm:px-10"
                  type="submit"
                >
                  Request a demo today
                </button>
              </Link>
            </>
          }
        />
        <div className="my-24"></div>
      </SolutionsPage>
    );
  }
}

export default VacationRentalsPage;
